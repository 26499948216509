<template>
  <div v-if="data._id">
    <div class="item" id="productGroupItem">
      <a @click="viewRelCategory(data._id._id)">
        <h3
          class="text-left"
          style="
            color: black;
            font-size: 17px;
            font-weight: 600;
            margin-top: 0px;
          "
        >
          {{ data._id.category_name?data._id.category_name:'' }}
        </h3>
        <div id="imgContiner" v-if="data.images">
          <div class="singalImg" v-if="data.images[0]?data.images[0].image:''">
            <img :src="data.images[0].image" class="img-responsive img" alt="brand" />
          </div>
          <div class="singalImg" v-if="data.images[1]?data.images[1].image:''">
            <img :src="data.images[1].image" class="img-responsive img" alt="brand" />
          </div>
          <div class="singalImg" v-if="data.images[2]?data.images[2].image:''">
            <img :src="data.images[2].image" class="img-responsive img" alt="brand" />
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductTile",
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    viewRelCategory(catId) {
      this.$router.push('/productlist?id='+ catId);
    }
  }
};
</script>
<style scoped>

#imgContiner {
  display: flex;
  justify-content: space-between;
}
#imgContiner .singalImg {
  flex-basis: 30%;
}
#imgContiner .singalImg img{
  width: 100%;
  height: 100px;
}
#productGroupItem {
  opacity: 1 !important;
  background-color: rgb(242 242 242);
  margin-right: 20px;
  /* width: 440px; */
  min-height: 180px;
  padding: 25px;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
}
#productGroupItem:hover{
-webkit-box-shadow: 0px 0px 8px 0px rgba(87,121,174,0.45); 
box-shadow: 0px 0px 8px 0px rgba(87,121,174,0.45);
}
</style>
