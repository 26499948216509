<template>
  <div>
    <div
      id="filtersectionmobile"
      class="module"
      style="background-color: white"
    >
      <h3 class="modtitle">Filter</h3>
      <div class="modcontent">
        <div class="table_layout filter-shopby">
          <div class="table_row">
            <!-- - - - - - - - - - - - - - Category filter - - - - - - - - - - - - - - - - -->
            <div class="table_cell" style="z-index: 103">
              <legend>Search</legend>
              <input
                class="form-control"
                v-model="searchtext"
                type="text"
                value
                size="50"
                autocomplete="off"
                placeholder="Search"
                name="search"
              />
            </div>
            <!--/ .table_cell -->
            <!-- - - - - - - - - - - - - - End of category filter - - - - - - - - - - - - - - - - -->

            <div v-for="(item, index) in filterdata.attributes" :key="index">
              <!-- <div class="table_cell" v-if="item.attribute_name === 'Color' " >

						<fieldset>

							<legend>Color</legend>

							<div class="row">

								<div class="col-sm-12">
									
									<ul class="simple_vertical_list">

										<li style="display:inline-grid; width:90px;" v-for="(itemlist, indexlist) in item.values" :key="indexlist">
											
											<input type="checkbox" name="" v-model="itemlist.selected" :id="'color_btn_' + itemlist._id">
											<label :for="'color_btn_' + itemlist._id" class="color_btn  " :class="((itemlist.lable).replace(/\s+/g, '')).toLowerCase()">{{itemlist.lable}}</label>

										</li> 

									</ul>

								</div>
 

							</div>

						</fieldset>

              </div>-->
              <!--/ .table_cell -->

              <div class="table_cell">
                <fieldset>
                  <legend>{{ item.attribute_name }}</legend>
                  <div
                    :id="item.attribute"
                    style="max-height: 200px; overflow: hidden"
                  >
                    <ul class="checkboxes_list" style="margin-top: 5px">
                      <li
                        v-for="(itemlist, indexlist) in item.values"
                        :key="indexlist"
                      >
                        <div>
                          <input
                            type="checkbox"
                            v-model="itemlist.selected"
                            name="category"
                            :id="'color_btn_' + itemlist._id"
                          />
                          <label :for="'color_btn_' + itemlist._id">
                            {{ itemlist.lable }}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </fieldset>
                <a
                  v-if="item.values.length > 6"
                  @click="viewMore(item.attribute)"
                >
                  <h6 :id="item.attribute + '_btn'">View More</h6>
                </a>
              </div>

              <!-- <div class="table_cell" v-if="item.attribute_name === 'size'">
                <fieldset>
                  <legend>{{ item.attribute_name }}</legend>
                  <div
                    :id="item.attribute"
                    style="max-height: 200px; overflow: hidden"
                  >
                    <ul class="checkboxes_list" style="margin-top: 5px">
                      <li
                        v-for="(itemlist, indexlist) in item.values"
                        :key="indexlist"
                      >
                        <input
                          type="checkbox"
                          v-model="itemlist.selected"
                          name="category"
                          :id="'color_btn_' + itemlist._id"
                        />
                        <label :for="'color_btn_' + itemlist._id">
                          {{ itemlist.lable }}
                        </label>
                      </li>
                    </ul>
                  </div>
                </fieldset>
                <a
                  v-if="item.values.length > 6"
                  @click="viewMore(item.attribute)"
                >
                  <h6 :id="item.attribute + '_btn'">View More</h6>
                </a>
              </div> -->
              <!--/ .table_cell -->

              <!-- <div class="table_cell">
                <fieldset>
                  <legend>{{ item.attribute_name }}</legend>
                  <div
                    :id="item.attribute"
                    style="max-height: 200px; overflow: hidden"
                  >
                    <ul class="checkboxes_list" style="margin-top: 5px">
                      <li
                        v-for="(itemlist, indexlist) in item.values"
                        :key="indexlist"
                      >
                        <input
                          type="checkbox"
                          v-model="itemlist.selected"
                          name="category"
                          :id="'color_btn_' + itemlist._id"
                        />
                        <label :for="'color_btn_' + itemlist._id">
                          {{ itemlist.lable }}
                        </label>
                      </li>
                    </ul>
                  </div>
                </fieldset>
                <a
                  v-if="item.values.length > 6"
                  @click="viewMore(item.attribute)"
                >
                  <h6 :id="item.attribute + '_btn'">View More</h6>
                </a>
              </div> -->
              <!--/ .table_cell -->
            </div>


            <!-- Backup on 08-10-2020 -->
            <!-- <div
              v-if="
                filterdata.brands.length > 0 && $route.name != 'Brand Items'
              "
            >
              <div class="table_cell">
                <fieldset>
                  <legend>Brand</legend>
                  <div
                    id="filterbrandblock"
                    style="max-height: 200px; overflow: hidden"
                  >
                    <ul class="checkboxes_list" style="margin-top: 5px">
                      <li
                        v-for="(item, index) in filterdata.brands"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          v-model="item.selected"
                          name="category"
                          :id="'color_btn_' + item._id"
                        />
                        <label
                          :for="'color_btn_' + item._id"
                          style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;
                          "
                        >
                          {{ item.brand_name }}
                        </label>
                      </li>
                    </ul>
                  </div>
                </fieldset>
                <a
                  v-if="filterdata.brands.length > 6"
                  @click="viewMore('filterbrandblock')"
                >
                  <h6 id="filterbrandblock_btn">View More</h6>
                </a>
              </div>
            </div> -->
            
            
            <!-- New Brand Block Starts Added on 08-10-2020 -->
            <div
              v-if="
                filterdata.brands.length > 0 && $route.name != 'Brand Items'
              "
            >
              <div class="table_cell">
                <fieldset>
                  <legend>Brand</legend>
                   <div class="row">
                        <div
                            class="col-sm-6"
                            id="filterbrandblock"
                            style="max-height: 200px; overflow: hidden"
                            v-for="(column, index) in brandColumns"
                            :key="index"
                        >
                            <ul class="checkboxes_list" style="margin-top: 5px">
                            <li
                                v-for="(item, index) in column"
                                :key="index"
                            >
                                <input
                                type="checkbox"
                                v-model="item.selected"
                                name="category"
                                :id="'color_btn_' + item._id"
                                />
                                <label
                                :class="{ activeBrand: item.selected }"
                                :for="'color_btn_' + item._id"
                                :style="'white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 80px;height: 35px;line-height: 35px;background: #e0ebeb url('+item.logo_img+') no-repeat center center;background-size: 100% 100%;'"
                                >
                                {{ item.logo_img == undefined?item.brand_name: '' }}
                                </label>
                            </li>
                            </ul>
                        </div>
                  </div>
                </fieldset>
                <a
                  v-if="filterdata.brands.length > 6"
                  @click="viewMore('filterbrandblock')"
                >
                  <h6 id="filterbrandblock_btn">View More</h6>
                </a>
              </div>
            </div>
              <!-- <div class="table_cell">
                <fieldset>
                  <legend>Size</legend>
                  <div class="row">
                  <div class="col-sm-6 col-xs-6"
                     v-for="(column, index) in sizeColumns"
                            :key="index"
                  >
                    <ul class="checkboxes_list">
                      <li v-for="(item, index) in column" :key="index">
                        <input
                          type="checkbox"
                          name="category"
                          v-model="selectedSizes"
                          :value="item.sizeValue"
                          :id="index"
                        />
                        <label :for="index" class="size-label">
                          {{ item.sizeLabel }}</label
                        >
                      </li>
                    </ul>
                  </div>
                  </div>
                </fieldset>
              </div> -->
            </div>
            <!-- Sizes Block Ends-->

            <!-- Price Range Block Starts-->
            <!-- <div v-if="this.$route.name=='Product List' || this.$route.name=='Store Product'">
               <div class="table_cell">
                    <fieldset>
                        <legend>Price</legend>
                        <div class="range">
                        Range :
                        <span class="min_val">LKR {{ minValue }}</span> -
                        <span class="max_val">LKR {{ maxValue }}</span>
                        <input
                            type="hidden"
                            name=""
                            class="min_value"
                            value="188.73"
                            v-model="minValue"
                        />
                        <input
                            type="hidden"
                            name=""
                            class="max_value"
                            value="335.15"
                            v-model="maxValue"
                        />
                        </div>
                        <div
                        id="slider"
                        class="ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"
                        >
                        <div
                            class="ui-slider-range ui-widget-header ui-corner-all"
                        ></div>
                        <span
                            class="ui-slider-handle ui-state-default ui-corner-all"
                        ></span>
                        <span
                            class="ui-slider-handle ui-state-default ui-corner-all"
                        ></span>
                        </div>
                    </fieldset>
                </div> 
            </div> -->
            
            <!-- Price Range Block Ends-->

            <!-- Color Block Starts-->
            <!-- <div v-if="this.$route.name=='Product List' || this.$route.name=='Store Product'">
                <div class="table_cell">
                    <fieldset>
                        <legend>Color</legend>

                        <div class="row">
                        <div
                            class="col-sm-6 col-xs-6"
                            v-for="(column, index) in columns"
                            :key="index"
                        >
                            <ul class="simple_vertical_list">
                            <li v-for="(item, index) in column" :key="index">
                                <input
                                type="checkbox"
                                name="color"
                                :id="'color_btn_' + item.colorId"
                                :value="item.colorValue"
                                v-model="selectedColors"
                                />
                                <label
                                :for="'color_btn_' + item.colorId"
                                :class="'color_btn ' + item.colorValue"
                                >{{ item.colorLabel }}</label
                                >
                            </li>
                            </ul>
                        </div>
                        </div>
                    </fieldset>
                </div>
            </div> -->
            <!-- Color Block Ends-->

            <!-- - - - - - - - - - - - - - Price - - - - - - - - - - - - - - - - -->
            <!-- <div class="table_cell">
					<fieldset>
						<legend>Price</legend>
						<div class="range">
							Range :
							<span class="min_val">$188.73</span> - 
							<span class="max_val">$335.15</span>
							<input type="hidden" name="" class="min_value" value="188.73">
							<input type="hidden" name="" class="max_value" value="335.15">
						</div>
						<div id="slider" class="ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all">
							<div class="ui-slider-range ui-widget-header ui-corner-all"></div>
							<span class="ui-slider-hitem.sizeLabel
            </div>-->
            <!--/ .table_cell -->

            <!-- - - - - - - - - - - - - - End price - - - - - - - - - - - - - - - - -->

            <!-- - - - - - - - - - - - - - Price - - - - - - - - - - - - - - - - -->

            <!-- - - - - - - - - - - - - - End price - - - - - - - - - - - - - - - - -->
          </div>
          <!--/ .table_row -->
          <footer class="bottom_box">
            <div class="buttons_row">
              <button
                @click="createFilterObj()"
                class="btn buyerprof-sbtn"
                style="color: white"
              >
                Search
              </button>
              <button
                @click="resetFilterObj()"
                class="btn buyerprof-sbtn"
                style="margin-left: 10px; color: white"
              >
                Reset
              </button>
            </div>
            <!--Back To Top-->
            <div class="back-to-top">
              <i class="fa fa-angle-up"></i>
            </div>
          </footer>
        </div>
        <!--/ .table_layout -->
      </div>
    </div>
</template>
<script>
import mixinProduct from "@/mixins/buyer/APIProduct";

export default {
  name: "left_menu",
  props: {
    data: {
      type: Object,
    },
  },
  mixins: [mixinProduct],
  data: function () {
    return {
      cID: "",
      filterdata: {
        brands: [],
      },
      sizes: [
        {
          sizeLabel: "s",
          sizeValue: "S",
        },
        {
          sizeLabel: "m",
          sizeValue: "M",
        },
        {
          sizeLabel: "l",
          sizeValue: "L",
        },
        {
          sizeLabel: "xl",
          sizeValue: "XL",
        },
      ],
      selectedSizes: [],
      colors: [
        {
          colorLabel: "Green",
          colorValue: "green",
          colorId: 1,
        },
        {
          colorLabel: "Yellow",
          colorValue: "yellow",
          colorId: 2,
        },
        {
          colorLabel: "Red",
          colorValue: "red",
          colorId: 3,
        },
        {
          colorLabel: "Blue",
          colorValue: "blue",
          colorId: 4,
        },
        {
          colorLabel: "Grey",
          colorValue: "grey",
          colorId: 5,
        },
        {
          colorLabel: "Orange",
          colorValue: "orange",
          colorId: 6,
        },
      ],
      selectedColors: [],
      sizeCols: 2,
      colorCols: 2,
      brandCols: 2,
      searchtext: "",
      newArray: [],
      minValue: 188.73,
      maxValue: 335.15
    };
  },
  computed: {
    columns() {
      let columns = [];
      let mid = Math.ceil(this.colors.length / this.colorCols);
      for (let col = 0; col < this.colorCols; col++) {
        columns.push(this.colors.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
    brandColumns() {
      let columns = [];
      let mid = Math.ceil(this.filterdata.brands.length / this.brandCols);
      for (let col = 0; col < this.brandCols; col++) {
        columns.push(this.filterdata.brands.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
    sizeColumns() {
      let columns = [];
      let mid = Math.ceil(this.sizes.length / this.sizeCols);
      for (let col = 0; col < this.sizeCols; col++) {
        columns.push(this.sizes.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
  },
  watch: {
    $route(to, from) {
      this.callFucntionsByOrder();
    },
    "$route.query.id"() {
      this.callFucntionsByOrder();
    }
  },
  beforemounted() {
    if ($("#slider").length) {
      window.startRangeValues = [28.0, 562.0];
      $("#slider").slider({
        range: true,
        min: 10.0,
        max: 580.0,
        values: window.startRangeValues,
        step: 0.01,

        slide: function (event, ui) {
          var min = ui.values[0].toFixed(2),
            max = ui.values[1].toFixed(2),
            range = $(this).siblings(".range");

          range.children(".min_value").val(min).next().val(max);

          range
            .children(".min_val")
            .text("LKR " + min)
            .next()
            .text("LKR " + max);
        },

        create: function (event, ui) {
          var $this = $(this),
            min = $this.slider("values", 0).toFixed(2),
            max = $this.slider("values", 1).toFixed(2),
            range = $this.siblings(".range");

          range.children(".min_value").val(min).next().val(max);

          range
            .children(".min_val")
            .text("LKR " + min)
            .next()
            .text("LKR " + max);
        },
      });
    }
  },
  created() {
    this.callFucntionsByOrder();
    // this.handleGetStores();
  },
  methods: {
    getURLcID() {
      if (this.$route.name ==='Product List') {
        let url = window.location.href.split("?id=");
        if (url[1]) {
          let idarray = url[1].split("&bid=");
          this.cID = idarray[0];
          this.bID = idarray[1];
          return idarray[0];
        }
      }
    },
    callFucntionsByOrder: async function () {
      localStorage.setItem("filterdata", null);

      this.$store.dispatch("fetchFilterData");

      this.getURLcID();
      await this.getProductsFiltersByID();
    },
    getProductsFiltersByID: async function () {
      try {
        if (this.$route.name ==='Product List') {
        let response = await this.getProductListFiltersByCategoryID(this.cID);
        let params = "category=" + this.cID;
        let response1 = await this.getProductListByCIDFilters(params);
        let array = [];
        this.newArray = [];
        for (let x = 0; x < response1.length; x++) {
          for (let z = 0; z < response.result.brands.length; z++) {
            if (response1[x].brand._id === response.result.brands[z]._id) {
              array.push(response.result.brands[z]);
            }
          }
        }

        // Remove duplicate items in array - start
        this.newArray = array;
        array = Array.from(new Set(this.newArray.map(JSON.stringify))).map(
          JSON.parse
        );
        // Remove duplicate items in array - end

        response.result.brands = array;
        this.filterdata = response.result;
        }
      } catch (error) {
        console.log(error);
      }
    },
    pushTo(pathlink) {
      this.$router.push(pathlink) ;
    },
    chlidClass(obj) {
      if (obj.child) {
        return "hadchild";
      } else {
        return "";
      }
    },
    createFilterObj() {
      let filterarray = {
        attributes: [],
        brands: [],
      };

      if (this.filterdata.attributes) {
        for (let index = 0; index < this.filterdata.attributes.length; index++) {
          let values = this.filterdata.attributes[index].values;
          for (let indexvalue = 0; indexvalue < values.length; indexvalue++) {
            if (values[indexvalue].selected) {
              filterarray.attributes.push(values[indexvalue]._id);
            }
          }
        }
      }


      // filter brands
      // let params = "category=" + this.cID;
      // let productlist = await this.getProductListByCIDFilters(params);
      if (this.filterdata.brands) {
        for (let index = 0; index < this.filterdata.brands.length; index++) {
          if (this.filterdata.brands[index].selected) {
            filterarray.brands.push(this.filterdata.brands[index]._id);
          }
        }
      }

      filterarray.searchtext = this.searchtext;

      localStorage.setItem("filterdata", JSON.stringify(filterarray));

      this.$store.dispatch("fetchFilterData");
    },
    resetFilterObj() {
      localStorage.setItem("filterdata", null);

      this.$store.dispatch("fetchFilterData");
      this.getProductsFiltersByID();
      this.searchtext = "";
      this.filterdata.brands = {};
      this.getURLcID();

      // Hard-coded data to be changed below
      this.selectedSizes = [];
      this.selectedColors = [];
      this.minValue = 188.73;
      this.maxValue = 335.15;
    },
    viewMore(id) {
      let currentheight = document
        .getElementById(id)
        .style.maxHeight.split("px");

      if (currentheight[0] <= 200) {
        document.getElementById(id).style.maxHeight = "400px";
        document.getElementById(id).style.overflowY = "scroll";
        document.getElementById(id + "_btn").innerHTML = "View Less";
      } else {
        document.getElementById(id).scrollTop = 0;
        document.getElementById(id).style.maxHeight = "200px";
        document.getElementById(id).style.overflow = "hidden";
        document.getElementById(id + "_btn").innerHTML = "View More";
      }
    },
  },
};
</script>
<style>
.static-cates ul li {
  padding: 0 15px;
  float: left;
  /* width: 16.66%; */
}

.static-cates ul li h4 {
  margin-top: 15px;
}
.buyerprof-sbtn {
  background: #de1a22;
  color: #ffffff;
  border: none;
}
.buyerprof-sbtn:hover {
  color: #000000;
}
.size-label {
  text-transform: uppercase;
}
#filterbrandblock .checkboxes_list input[type="checkbox"] + label{
    padding-left: 0px;
    text-align: center;
    line-height: 30px;
}
#filterbrandblock .checkboxes_list input[type="checkbox"] + label::before{
    display: none;
}
.activeBrand{
    border: 1px solid cyan;
    opacity: 0.6;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
