<template>
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          id="slider-group"
        >
          <div
                  id="metaInfoGroup"
                  class="yt-content-slider contentslider"
                  data-rtl="yes"
                  data-autoplay="yes"
                  data-delay="4"
                  data-speed="0.6"
                  data-margin="0"
                  data-items_column00="1"
                  data-items_column0="1"
                  data-items_column1="1"
                  data-items_column2="1"
                  data-items_column3="1"
                  data-items_column4="1"
                  data-arrows="no"
                  data-pagination="no"
                  data-lazyload="yes"
                  data-loop="no"
                >
                <!-- Item boxes -->
                <div class="storepage-metainfobox storepage-metainfobox--year">
                  <h4>Joined</h4>
                  <div class="metainfo-content--two-col">
                      <div class="metainfo-inner-left">
                        1
                      </div>
                      <div class="metainfo-inner-right">
                        +<br/>
                        Years 
                      </div>
                  </div>
                </div>
                <div class="storepage-metainfobox storepage-metainfobox--sellsize">
                  <h4>Seller Ratings</h4>
                  <i class="fa fa-bar-chart test-graph-icon" aria-hidden="true" style="margin-left: 15px;"></i>
                </div>
                <div class="storepage-metainfobox storepage-metainfobox--shippedtime">
                  <h4>Shipped on Time</h4>
                   <div class="metainfo-content--two-col">
                      <div class="metainfo-inner-left">
                        84%
                      </div>
                      <div class="metainfo-inner-right">
                        This is average for<br/>
                        sellers<br/>
                        in same category
                      </div>
                  </div>
                </div>
                <div class="storepage-metainfobox storepage-metainfobox--chatres">
                  <h4>Chat</h4>
                  <div class="metainfo-content--one-col">
                      <div class="chat-res-rate-time-desc">
                          Chat response time
                      </div>
                      <div class="chat-res-rate-time-count">
                          Active in: hours
                      </div>    
                  </div>
                </div>
          </div>
        </div>
</template>
<script>
import ProductTileGroup from "@/components/product/product_tile_group.vue";
import mixinCommon from "@/mixins/APICommon";
export default {
  components: {
    ProductTileGroup,
  },
  mixins: [mixinCommon],
  data() {
    return {
      // mostPopularItems: [
      //   {
      //     image1:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image2:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image3:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //   },
      //   {
      //     image1:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image2:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image3:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //   },
      //   {
      //     image1:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image2:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image3:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //   },
      //   {
      //     image1:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image2:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image3:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //   },
      //   {
      //     image1:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image2:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //     image3:
      //       "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png",
      //   }
      // ]
    };
  },
  created() {
    // this.getMostPopuler();
  },
  methods: {
    // getMostPopuler: async function() {
    //   try {
    //     let response = await this.getMostPopulerCatList();
    //     this.mostPopularItems = response;

    //     console.log(this.mostPopularItems);


    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  }
};
</script>
<style>

#productGroup {
  max-height: 200px !important;
  overflow: hidden !important;
}
#slider-group {
  overflow: hidden !important;
}
</style>